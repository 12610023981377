"use client";
import React from "react";
import Image from "next/image";
import main from "@cybrix/app/(fragments)/(header)/header_main.webp";
import transportation from "@cybrix/app/(fragments)/(header)/main_transportation.webp";
import telecom from "@cybrix/app/(fragments)/(header)/main_telecom.webp";
import CustomDot from "@cybrix/components/CustomDot";
import dynamic from "next/dynamic";

const Carousel = dynamic(() => import("react-multi-carousel"), {
	loading: () => {
		return (
			<div className={"w-full py-16"}>
				<div
					className={
						"grid grid-cols-10  grid-rows-6 w-full max-w-7xl m-auto px-4 select-none"
					}
				>
					<div
						className={
							"col-start-1 col-end-11 md:col-end-5 row-start-1 row-end-2 md:row-start-2 md:row-end-4  text-3xl lg:text-4xl font-bold leading-tight"
						}
					>
						Beyond Code
						<br /> To Business Success
					</div>
					<div
						className={
							" h-96 md:h-64 lg:h-96 col-start-1 md:col-start-5 col-end-11 row-start-2 md:row-start-1  row-end-6 rounded-3xl relative z-0 overflow-hidden"
						}
					>
						<Image
							src={main}
							alt={"header"}
							fill={true}
							placeholder={"blur"}
							className={"object-cover"}
						/>
					</div>
					<div
						className={
							" col-start-3 md:col-start-1 col-end-11 md:col-end-8 lg:col-end-7 row-start-4 lg:row-start-5 row-end-7 bg-gray-950 bg-opacity-70 grid grid-cols-5 p-8 rounded-3xl relative z-20 items-center max-md:flex max-md:flex-col max-md:items-baseline max-md:justify-between"
						}
					>
						<div
							className={
								"col-start-1 col-end-3 text-gray-200 text-xl lg:text-2xl font-medium gap-4"
							}
						>
							Supply
							<br /> Chain
							<br /> Management
						</div>
						<div className={"col-start-3 col-end-6 text-gray-200 text-sm"}>
							Our team of engineers offers profound expertise in developing solutions
							for logistics, distribution & procurement platforms, warehousing, and
							many more
						</div>
					</div>
				</div>
			</div>
		);
	},
	ssr: false,
});

const responsive = {
	desktop: {
		breakpoint: { max: 5000, min: 1024 },
		items: 1,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 1,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
	},
};

const sections = [
	{
		title: (
			<>
				Beyond Code
				<br /> To Business Success
			</>
		),
		image: main,
		subtitle: (
			<>
				Supply
				<br /> Chain
				<br /> Management
			</>
		),
		text: (
			<>
				Our team of engineers offers profound expertise in developing solutions for
				logistics, distribution & procurement platforms, warehousing, and many more
			</>
		),
	},
	{
		title: (
			<>
				Beyond Code
				<br /> To Business Success
			</>
		),
		image: transportation,
		subtitle: <>Transportation</>,
		text: (
			<>
				Boost up your company’s performance by integrating fast and responsive digital
				systems that will take your clients’ traveling experience to another level
			</>
		),
	},
	{
		title: (
			<>
				Beyond Code
				<br /> To Business Success
			</>
		),
		image: telecom,
		subtitle: <>Telecom</>,
		text: (
			<>
				Increase your company’s efficiency with cutting-edge technologies for unimpeded
				communication, scalable networks, and data-driven decisions
			</>
		),
	},
];

const Header = () => {
	return (
		<div className={"w-screen max-w-7xl m-auto max-md:pb-8 md:py-16 "}>
			<Carousel
				className={"w-full py-16"}
				arrows={false}
				responsive={responsive}
				ssr={false}
				showDots={true}
				autoPlay={true}
				infinite={true}
				autoPlaySpeed={5000}
				itemClass={"px-4"}
				customDot={<CustomDot />}
				draggable={false}
				dotListClass={"absolute bottom-24 lg:bottom-32 left-1/2 transform -translate-x-1"}
			>
				{sections.map((el, index) => (
					<div
						className={
							"grid grid-cols-10  grid-rows-6 w-full max-w-7xl m-auto px-4 select-none"
						}
						key={index}
					>
						<div
							className={
								"col-start-1 col-end-11 md:col-end-5 row-start-1 row-end-2 md:row-start-2 md:row-end-4  text-3xl lg:text-4xl font-bold leading-tight"
							}
						>
							{el.title}
						</div>
						<div
							className={
								" h-96 md:h-64 lg:h-96 col-start-1 md:col-start-5 col-end-11 row-start-2 md:row-start-1  row-end-6 rounded-3xl relative z-0 overflow-hidden"
							}
						>
							<Image
								src={el.image}
								alt={"header"}
								fill={true}
								placeholder={"blur"}
								className={"object-cover"}
							/>
						</div>
						<div
							className={
								" col-start-3 md:col-start-1 col-end-11 md:col-end-8 lg:col-end-7 row-start-4 lg:row-start-5 row-end-7 bg-gray-950 bg-opacity-70 grid grid-cols-5 p-8 rounded-3xl relative z-20 items-center max-md:flex max-md:flex-col max-md:items-baseline max-md:justify-between"
							}
						>
							<div
								className={
									"col-start-1 col-end-3 text-gray-200 text-xl lg:text-2xl font-medium gap-4"
								}
							>
								{el.subtitle}
							</div>
							<div className={"col-start-3 col-end-6 text-gray-200 text-sm"}>
								{el.text}
							</div>
						</div>
					</div>
				))}
			</Carousel>
		</div>
	);
};

export default Header;
