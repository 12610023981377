import(/* webpackMode: "eager" */ "/home/runner/work/cybrix/cybrix/node_modules/.pnpm/next@14.0.3_@babel+core@7.21.8_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/home/runner/work/cybrix/cybrix/src/app/(fragments)/(expertise)/artificial_intelligence.webp");
import(/* webpackMode: "eager" */ "/home/runner/work/cybrix/cybrix/src/app/(fragments)/(expertise)/big_data_engineering.webp");
import(/* webpackMode: "eager" */ "/home/runner/work/cybrix/cybrix/src/app/(fragments)/(expertise)/it_operations.webp");
import(/* webpackMode: "eager" */ "/home/runner/work/cybrix/cybrix/src/app/(fragments)/(expertise)/quolity_assurance.webp");
import(/* webpackMode: "eager" */ "/home/runner/work/cybrix/cybrix/src/app/(fragments)/(expertise)/software_develpment.webp");
import(/* webpackMode: "eager" */ "/home/runner/work/cybrix/cybrix/src/app/(fragments)/(expertise)/user_experiance_design.webp");
import(/* webpackMode: "eager" */ "/home/runner/work/cybrix/cybrix/src/app/(fragments)/(header)/Header.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/cybrix/cybrix/src/app/(fragments)/(services)/dedicated_teams.webp");
import(/* webpackMode: "eager" */ "/home/runner/work/cybrix/cybrix/src/app/(fragments)/(services)/full_software_delivery.webp");
import(/* webpackMode: "eager" */ "/home/runner/work/cybrix/cybrix/src/app/(fragments)/(services)/tech_consultancy.webp");
import(/* webpackMode: "eager" */ "/home/runner/work/cybrix/cybrix/src/app/(fragments)/(technology_stack)/TechnologyStack.tsx")