import { FC } from "react";

const CustomDot: FC<{
	onClick?: () => void;
	onMove?: () => void;
	index?: number;
	active?: boolean;
	carouselState?: { currentSlide: number; deviceType: number };
}> = (props) => {
	const { onClick, active } = props;

	return (
		<div
			className={
				active
					? "w-16 h-1 bg-cyan-400 mx-2 cursor-pointer"
					: "w-8 h-1 bg-gray-500 mx-2 cursor-pointer"
			}
			onClick={() => onClick?.()}
		/>
	);
};

export default CustomDot;
