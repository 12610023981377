"use client";
import React from "react";
import "react-multi-carousel/lib/styles.css";
import CustomDot from "@cybrix/components/CustomDot";

import DigitalOcean from "./(clouds_technology)/digital_ocean.svg";
import Azure from "./(clouds_technology)/azure.svg";
import Aws from "./(clouds_technology)/aws.svg";
import Gcp from "./(clouds_technology)/gcp.svg";
import Hetzner from "./(clouds_technology)/hetzner.svg";

import Neo4j from "./(data_warehouses)/neo4j.svg";
import Elastic from "./(data_warehouses)/elasticsearch.svg";
import Mongo from "./(data_warehouses)/mongo.svg";
import Mysql from "./(data_warehouses)/mysql.svg";
import Postgress from "./(data_warehouses)/postgress.svg";
import Redis from "./(data_warehouses)/redis.svg";

import Docker from "./(dev_ops)/docker.svg";
import Sonarqube from "./(dev_ops)/sonarqube.svg";
import Terraform from "./(dev_ops)/terraform.svg";
import CICD from "./(dev_ops)/ci-cd.svg";
import Kubernetes from "./(dev_ops)/kubernetes.svg";
import Vagrant from "./(dev_ops)/vagrant.svg";

import Cassandra from "./(big_data)/cassandra.svg";
import Hadoop from "./(big_data)/hadoop.svg";
import Kafka from "./(big_data)/kafka.svg";
import Spark from "./(big_data)/spark.svg";
import Splunk from "./(big_data)/splunk.svg";
import Tableau from "./(big_data)/tableau.svg";

import Android from "./(mobile_technology)/android.svg";
import Ios from "./(mobile_technology)/ios.svg";
import Flutter from "./(mobile_technology)/flutter.svg";
import Kotlin from "./(mobile_technology)/kotlin.svg";
import Xmamarin from "./(mobile_technology)/xamarin.svg";
import DotnetMaui from "./(mobile_technology)/dotnetmaui.svg";

import Csharp from "./(software_development)/c-sharp.svg";
import Java from "./(software_development)/java.svg";
import Python from "./(software_development)/python.svg";
import Scala from "./(software_development)/scala.svg";
import Typescript from "./(software_development)/typescript.svg";
import Go from "./(software_development)/go.svg";

import Angular from "./(web_technologies)/angular.svg";
import Reactt from "./(web_technologies)/react.svg";
import NextJs from "./(web_technologies)/nextjs.svg";
import Html from "./(web_technologies)/html.svg";
import Vue from "./(web_technologies)/vue.svg";
import Svelte from "./(web_technologies)/svelte.svg";
import Carousel from "react-multi-carousel";

const responsive = {
	desktop: {
		breakpoint: { max: 5000, min: 1024 },
		items: 3,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 2,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
	},
};

const cards = [
	{
		name: "Clouds Technology",
		items: [DigitalOcean, Azure, Aws, Gcp, Hetzner],
	},
	{
		name: "Data Warehouses",
		items: [Neo4j, Elastic, Mongo, Mysql, Postgress, Redis],
	},
	{
		name: "Dev Ops",
		items: [Docker, Sonarqube, Terraform, CICD, Vagrant, Kubernetes],
	},
	{
		name: "Big Data Environment",
		items: [Cassandra, Hadoop, Kafka, Spark, Tableau, Splunk],
	},
	{
		name: "Mobile Technology",
		items: [Android, Ios, Flutter, Kotlin, Xmamarin, DotnetMaui],
	},
	{
		name: "Software Development",
		items: [Csharp, Java, Python, Scala, Typescript, Go],
	},
	{
		name: "Web technologies",
		items: [Angular, Reactt, NextJs, Html, Vue, Svelte],
	},
];

const TechnologyStack = () => {
	return (
		<div className={"bg-gray-100 shadow-custom"}>
			<div
				className={
					"w-full py-16 flex flex-col gap-4 relative max-w-7xl m-auto px-8 md:px-4"
				}
			>
				<h1 className={"font-bold text-2xl lg:text-3xl"}>Technology Stack</h1>
				<p className={"font-medium"}>
					Cybrix engineers use the latest frameworks and technologies tailored to match
					your business needs
				</p>
				<Carousel
					className={"w-full py-16"}
					responsive={responsive}
					ssr={false}
					showDots={true}
					autoPlay={true}
					infinite={true}
					autoPlaySpeed={5000}
					itemClass={"px-4"}
					customDot={<CustomDot />}
				>
					{cards.map((card) => (
						<div
							className={"w-full h-72 bg-white rounded-2xl flex flex-col gap-4 p-8"}
							key={card.name}
						>
							<div className={"uppercase font-bold"}>{card.name}</div>
							<div className={"grid grid-cols-3 relative gap-8 p-8"}>
								{card.items.map((Icon, key) => (
									<Icon key={key} className={"object-cover h-16 w-16"} />
								))}
							</div>
						</div>
					))}
				</Carousel>
			</div>
		</div>
	);
};

export default TechnologyStack;
